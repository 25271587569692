//Created on July 9th, 2021
//Updated on January 19th, 2023
//Author: Vaibhavi More
import React from 'react';
import { styled } from '@mui/system';
import { isMobile } from "react-device-detect";
import Typography from '@mui/material/Typography';
import { Button, Paper, Backdrop } from '@mui/material';
// import background from '../../src/loginBackgroundBlueBase.png';
import background from '../assets/img/db-bg.jpg';
import amzn_sellercentral_logo from './amazonGenericTransparent.png';
import amzn_vendorcentral_logo from './amazonGenericTransparent.png';
import { secureStorage_getItem, secureStorage_setItem } from '../utility/secureStorage';
import * as config from '../Config/config';
import { resetStorage } from '../utility/resetStorage';
import apigClientFactory from '../chalice-javascript-sdk/apigClient.js';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { v4 as uuidv4 } from 'uuid';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    paper: {
        padding: '16px',
        textAlign: 'center',
    },
});

//Amazon Seller Central
class Step1 extends React.Component {
    state = {
        loading: false,
        error: null,
        authStep: 'initial' // Possible values: initial, authorizing, error
    };

    componentDidMount() {
        console.log('Step1: Component mounted');
        this.validateInitialState();
        this.handleFetchClick();
    }

    validateInitialState = () => {
        const customername = secureStorage_getItem("customername");
        const accesstoken = localStorage.getItem("accesstoken");

        if (!customername || !accesstoken) {
            this.setState({
                error: 'Missing required authentication data',
                authStep: 'error'
            });
            this.logError('Missing required authentication data');
            return false;
        }
        return true;
    }

    logError = async (error, additionalInfo = {}) => {
        const apigClient = apigClientFactory.newClient();
        const accesstoken = localStorage.getItem("accesstoken");

        try {
            await apigClient.awsopsGenerateLogPut('', {
                customerid: secureStorage_getItem("customername"),
                info: `Error in authorization flow: ${error}`,
                ...additionalInfo
            }, {
                headers: { "Authorization": accesstoken }
            });
        } catch (err) {
            console.error('Failed to log error:', err);
        }
    }

    handleClick = async () => {
        try {
            this.setState({ loading: true, authStep: 'authorizing' });
            const apigClient = apigClientFactory.newClient();
            const accesstoken = localStorage.getItem("accesstoken");

            // Log current access token
            await apigClient.awsopsGenerateLogPut('', {
                customerid: secureStorage_getItem("customername"),
                info: 'Current access token for authorization',
                step: 'authorization_start',
                token: {
                    access_token: `${accesstoken.substring(0, 10)}...`
                }
            }, {
                headers: { "Authorization": accesstoken }
            });

            // Generate and store state parameter for CSRF protection
            const stateParam = uuidv4();
            secureStorage_setItem("oauth_state", stateParam);

            // Log state parameter
            await apigClient.awsopsGenerateLogPut('', {
                customerid: secureStorage_getItem("customername"),
                info: 'Generated state parameter for CSRF protection',
                step: 'state_parameter_generated',
                state: stateParam
            }, {
                headers: { "Authorization": accesstoken }
            });

            const customername = secureStorage_getItem("customername");

            // Determine region and URL
            const tempRegion = this.determineRegion(customername);
            const url = this.determineURL(tempRegion);

            // Store region and URL in localStorage
            localStorage.setItem("region", tempRegion);
            localStorage.setItem("url", url);

            // Construct authorization URL
            const app_id = config.amazon_app_id;
            const authURL = `${url}/apps/authorize/consent?application_id=${app_id}&state=${stateParam}&version=beta`;

            // Log authorization attempt
            await apigClient.awsopsGenerateLogPut('', {
                customerid: customername,
                info: `Initiating authorization - URL: ${authURL}`,
                step: 'authorization_initiated'
            }, {
                headers: { "Authorization": accesstoken }
            });

            // Redirect to Amazon authorization page
            window.location = authURL;

        } catch (error) {
            this.setState({
                error: 'Failed to initiate authorization',
                loading: false,
                authStep: 'error'
            });
            this.logError(error);
        }
    }

    determineRegion = (customername) => {
        let tempRegion = 'US'; // Default region
        const apigClient = apigClientFactory.newClient();
        const accesstoken = localStorage.getItem("accesstoken");

        const regions = config.marketplaceRegion;
        Object.entries(regions).forEach(([region, customers]) => {
            if (customers.includes(customername)) {
                tempRegion = region;
            }
        });

        // Log region determination
        apigClient.awsopsGenerateLogPut('', {
            customerid: customername,
            info: `Region determined: ${tempRegion}`,
            step: 'region_determination'
        }, {
            headers: { "Authorization": accesstoken }
        });

        return tempRegion;
    }

    determineURL = (region) => {
        if (this.props.location.pathname.includes('amazon-vendor-central')) {
            if (this.props.location.search.includes('Canada')) {
                return 'https://vendorcentral.amazon.ca';
            }
            return config.vendorCentralURL[region];
        }
        return config.sellerCentralURL[region];
    }

    renderAuthorizationStatus() {
        const { loading, error, authStep } = this.state;

        if (error) {
            return (
                <Alert severity="error" style={{ margin: '20px' }}>
                    {error}
                </Alert>
            );
        }

        if (loading) {
            return (
                <div style={{ textAlign: 'center', margin: '20px' }}>
                    <CircularProgress />
                    <Typography variant="body2" style={{ marginTop: '10px' }}>
                        Initiating authorization...
                    </Typography>
                </div>
            );
        }

        return null;
    }

    handleLogout = async () => {
        console.log('Handling logout');
        const apigClient = apigClientFactory.newClient();
        const accesstoken = localStorage.getItem("accesstoken");

        await apigClient.awsopsGenerateLogPut('', {
            customerid: secureStorage_getItem("customername"),
            info: 'User logged out during authorization process',
            step: 'authorization_logout'
        }, {
            headers: { "Authorization": accesstoken }
        });

        resetStorage();
        this.props.history.push({
            pathname: '/login'
        });
    }

    handleFetchClick = async () => {
        console.log('Handling fetch click');
        const tempSellerId = secureStorage_getItem('selling_partner_id');
        console.log('Temporary Seller ID:', tempSellerId);
        this.fetchCustomerID(`${tempSellerId}`);
    }

    fetchCustomerID = async (sellerid) => {
        const apigClient = apigClientFactory.newClient();
        const accesstoken = localStorage.getItem("accesstoken");

        console.log('Fetching customer ID for sellerid:', sellerid);
        try {
            // Log access token being used for fetch
            await apigClient.awsopsGenerateLogPut('', {
                customerid: secureStorage_getItem("customername"),
                info: `Fetching CustomerID with access token`,
                step: 'fetch_customer_id_started',
                token: {
                    access_token: `${accesstoken.substring(0, 10)}...`
                }
            }, {
                headers: { "Authorization": accesstoken }
            });

            const response = await fetch(`https://f4q7s67aunnfm7zv3bepcutd7m0rmhcd.lambda-url.us-east-1.on.aws/sellerid/${sellerid}`);
            const responseData = await response.json();

            console.log('Fetched CustomerID:', responseData.data[0].CustomerID);
            secureStorage_setItem('customeridfetched', responseData.data[0].CustomerID);

            // Log successful fetch with token used
            await apigClient.awsopsGenerateLogPut('', {
                customerid: secureStorage_getItem("customername"),
                info: `Successfully fetched CustomerID with access token`,
                step: 'fetch_customer_id_success',
                token: {
                    access_token: `${accesstoken.substring(0, 10)}...`
                },
                customer_id: responseData.data[0].CustomerID
            }, {
                headers: { "Authorization": accesstoken }
            });

        } catch (error) {
            console.error('Error fetching customer ID:', error);
            secureStorage_setItem('customeridfetched', '');

            // Log fetch error
            await apigClient.awsopsGenerateLogPut('', {
                customerid: secureStorage_getItem("customername"),
                info: `Error fetching CustomerID: ${error.message}`,
                step: 'fetch_customer_id_error'
            }, {
                headers: { "Authorization": accesstoken }
            });
        }
    }

    handlePopupClose = () => {
        console.log('Handling popup close');
        this.handleLogout();
    }

    render() {
        console.log('Rendering component');
        document.title = "eHouse Studio";


        var amzn_logo = <img src={amzn_sellercentral_logo} style={{ maxWidth: '200px', maxHeight: '100%', marginLeft: 3 + '%', marginRight: "auto" }} alt="Amazon Seller Central" />;
        var disc1 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 2 + '%', color: "black", fontSize: 18 + "px", marginTop: 100 + "px", fontWeight: "bold" }}>Disclaimer: By clicking this button you authorize eHouse Studio to access your Seller Central data over REST APIs.</Typography>;
        var disc2 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 5 + '%', color: "black", fontSize: 18 + "px" }}>We will redirect you to Amazon selling APIs consent page where you can grant permissions to eHouse Studio app to access your seller central data over REST APIs.</Typography>;

        if (this.props.location["pathname"].includes('vlomni-authorize-amazon-vendor-central')) {
            console.log('Vendor Central authorization path detected');
            amzn_logo = <img src={amzn_vendorcentral_logo} style={{ maxWidth: '200px', maxHeight: '100%', marginLeft: 3 + '%', marginRight: "auto" }} alt="Amazon Seller Central" />;
            disc1 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 2 + '%', color: "black", fontSize: 18 + "px", marginTop: 100 + "px", fontWeight: "bold" }}>Disclaimer: By clicking this button you authorize eHouse Studio to access your Vendor Central data over REST APIs.</Typography>;
            disc2 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 5 + '%', color: "black", fontSize: 18 + "px" }}>We will redirect you to Amazon selling APIs consent page where you can grant permissions to eHouse Studio app to access your vendor central data over REST APIs.</Typography>;
        }

        const content = (
            <div style={{ position: "absolute", top: "30%", left: "50%", marginTop: "5%", verticalAlign: "middle", transform: "translate(-50%, -50%)" }}>
                {amzn_logo}
                <div style={{ backgroundColor: "white", borderRadius: "25px", border: "6px solid", borderColor: "#d7d9de" }}>
                    <Typography variant="h4" style={{ color: "black", fontSize: "35px", marginLeft: "2%", marginRight: "auto", marginTop: "40px", textAlign: "center" }}>
                        Welcome to eHouse Studio
                    </Typography>

                    {this.renderAuthorizationStatus()}

                    {disc1}
                    {disc2}
                    <Button
                        variant="contained"
                        disabled={this.state.loading}
                        style={{
                            backgroundColor: "#4E2DD2",
                            color: "white",
                            fontWeight: "bold",
                            fontFamily: "Lato",
                            marginTop: "10px",
                            float: "right"
                        }}
                        onClick={this.handleClick}
                    >
                        {this.state.loading ? 'Authorizing...' : 'Authorize Me'}
                    </Button>
                </div>
            </div>
        );

        var dialog = (
            <div className="time-out-message" style={{ fontFamily: "Lato", display: 'none' }}>
                <Backdrop style={{ zIndex: "10000", backgroundColor: "rgba(0,0,0,0.5)" }} open={true}>
                </Backdrop>
                <div style={{ position: "fixed", zIndex: 10001, top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: 'white', borderRadius: '0.5em', padding: '2em' }} >
                    <span id="message-id">Your session has timed out.<br /> <span style={{ textDecoration: "underline", cursor: "pointer", color: "dodgerblue" }} onClick={this.handlePopupClose.bind(this)}>Click here</span> to log in again.</span>
                </div>
            </div>
        );

        if (isMobile) {
            console.log('Rendering mobile view');
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {content}
                        <img style={{ width: 100 + "%", height: 100 + '%', backgroundColor: "#4E2DD2" }} src={background} alt="Background"></img>
                    </Paper>
                    {dialog}
                </div>
            );
        } else {
            console.log('Rendering desktop view');
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {content}
                        <img style={{ width: 100 + "%", height: 100 + "%", backgroundColor: "#4E2DD2" }} src={background} alt="Background"></img>
                        {dialog}
                    </Paper>
                </div>
            );
        }
    }
}

export default styled(Step1)(styles);
