//Created on July 9th, 2021
//Author: Vaibhavi More
import React from 'react';
import queryString from 'query-string'
import { isMobile } from "react-device-detect";
import { styled } from '@mui/system';
import { Typography, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import * as config from '../Config/config';
import background from '../assets/img/db-bg.jpg';
import { secureStorage_getItem, secureStorage_setItem } from '../utility/secureStorage';
import apigClientFactory from '../chalice-javascript-sdk/apigClient';
import spcredsGQ from '../../src/graphql/amazonspcreds';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: '16px',
        textAlign: 'center'
    },
});

class Step2 extends React.Component {
    state = {
        loading: true,
        error: null,
        success: false,
        processingStep: 'initializing' // Possible values: initializing, processing_auth_code, exchanging_tokens, storing_credentials, completed, error
    };

    async componentDidMount() {
        try {
            var values = queryString.parse(this.props.location.search)
            //amazon_state, selling_partner_id 
            if (values.amazon_state) secureStorage_setItem("amazon_state", values.state)
            if (values.selling_partner_id) secureStorage_setItem("selling_partner_id", values.selling_partner_id)
            if (values.spapi_oauth_code) secureStorage_setItem("spapi_oauth_code", values.spapi_oauth_code)

            await this.processAuthorization();
        } catch (error) {
            this.handleError('Authorization failed', error);
        }
    }

    processAuthorization = async () => {
        const values = new URLSearchParams(this.props.location.search);
        const accesstoken = localStorage.getItem("accesstoken");
        const apigClient = apigClientFactory.newClient();

        try {
            // Log start of authorization process
            await apigClient.awsopsGenerateLogPut('', {
                customerid: secureStorage_getItem("customername"),
                info: 'Starting authorization process',
                step: 'authorization_process_start'
            }, {
                headers: { "Authorization": accesstoken }
            });

            // Validate state parameter to prevent CSRF
            const storedState = secureStorage_getItem("oauth_state");
            const receivedState = values.get('state');

            if (storedState !== receivedState) {
                await apigClient.awsopsGenerateLogPut('', {
                    customerid: secureStorage_getItem("customername"),
                    info: 'State parameter mismatch detected - possible CSRF attempt',
                    step: 'authorization_csrf_error'
                }, {
                    headers: { "Authorization": accesstoken }
                });
                throw new Error('State parameter mismatch - possible CSRF attack');
            }

            // Process authorization code if present
            const authCode = values.get('spapi_oauth_code');
            if (authCode) {
                await apigClient.awsopsGenerateLogPut('', {
                    customerid: secureStorage_getItem("customername"),
                    info: 'Authorization code received',
                    step: 'authorization_code_received'
                }, {
                    headers: { "Authorization": accesstoken }
                });
                await this.processAuthorizationCode(authCode, accesstoken);
            }

            // Store seller ID if present
            const sellerID = values.get('selling_partner_id');
            if (sellerID) {
                await apigClient.awsopsGenerateLogPut('', {
                    customerid: secureStorage_getItem("customername"),
                    info: `Seller ID received: ${sellerID}`,
                    step: 'seller_id_received'
                }, {
                    headers: { "Authorization": accesstoken }
                });
            }

            // Log successful completion
            await apigClient.awsopsGenerateLogPut('', {
                customerid: secureStorage_getItem("customername"),
                info: 'Authorization process completed successfully',
                step: 'authorization_complete'
            }, {
                headers: { "Authorization": accesstoken }
            });

            this.setState({ loading: false, success: true, processingStep: 'completed' });
        } catch (error) {
            this.handleError('Authorization failed', error);
        }
    }

    processAuthorizationCode = async (authCode, accesstoken) => {
        const apigClient = apigClientFactory.newClient();
        this.setState({ processingStep: 'exchanging_tokens' });

        const tokenExchangeParams = {
            grant_type: "authorization_code",
            code: authCode,
            redirect_uri: 'https://myvlomni.com/vlomni-amazon-api-authorize',
            client_id: config.amazon_client_id,
            client_secret: config.amazon_client_secret
        };

        try {
            // Log token exchange start
            await apigClient.awsopsGenerateLogPut('', {
                customerid: secureStorage_getItem("customername"),
                info: 'Starting token exchange process',
                step: 'token_exchange_start'
            }, {
                headers: { "Authorization": accesstoken }
            });

            const response = await fetch('https://api.amazon.com/auth/o2/token', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(tokenExchangeParams)
            });

            if (!response.ok) {
                throw new Error(`Token exchange failed: ${response.statusText}`);
            }

            const data = await response.json();

            // Log token data (safely)
            await apigClient.awsopsGenerateLogPut('', {
                customerid: secureStorage_getItem("customername"),
                info: `Token exchange completed. Access Token: ${data.access_token}... Refresh Token: ${data.refresh_token}...`,
                step: 'token_exchange_success',
                tokens: {
                    access_token: data.access_token,
                    refresh_token: data.refresh_token,
                    token_type: data.token_type,
                    expires_in: data.expires_in
                }
            }, {
                headers: { "Authorization": accesstoken }
            });

            await this.storeCredentials(data);

        } catch (error) {
            // Error logging is handled in handleError method
            this.handleError('Token exchange failed', error);
            throw error;
        }
    }

    storeCredentials = async (tokenData) => {
        this.setState({ processingStep: 'storing_credentials' });
        const apigClient = apigClientFactory.newClient();
        const accesstoken = localStorage.getItem("accesstoken");

        const region = localStorage.getItem("region") || 'US';
        const marketPlaceID = config.marketPlaceID[region];

        const credentials = {
            CustomerID: secureStorage_getItem('customername'),
            SellerID: secureStorage_getItem('selling_partner_id'),
            MarketPlaceID: marketPlaceID,
            AccessToken: tokenData.access_token,
            RefreshToken: tokenData.refresh_token
        };

        try {
            // Log start of credential storage process
            await apigClient.awsopsGenerateLogPut('', {
                customerid: secureStorage_getItem("customername"),
                info: 'Starting SP-API credentials storage process',
                step: 'storing_credentials_start'
            }, {
                headers: { "Authorization": accesstoken }
            });

            // Store in existing system
            await apigClient.dbopsInsertAmazonMWS_CredsPost("", credentials, {
                headers: { "Authorization": accesstoken }
            });

            // Store in DynamoDB
            // First check if credentials already exist
            const filters = `{ CustomerID: {eq: ${secureStorage_getItem("CustomerID")}}, MarketPlaceId: {eq: "${marketPlaceID}"}, SellerID: {eq: "${secureStorage_getItem('selling_partner_id')}"}}`;

            try {
                const dynamoCredentials = {
                    CustomerID: secureStorage_getItem('CustomerID'),
                    SellerID: secureStorage_getItem('selling_partner_id'),
                    MarketPlaceID: marketPlaceID,
                    AccessToken: tokenData.access_token,
                    RefreshToken: tokenData.refresh_token
                };

                const existingCreds = await spcredsGQ.listAmazonSPCreds(filters, "CustomerID");

                if (!existingCreds.length) {
                    // Create new credentials
                    await spcredsGQ.createAmazonSPCreds(dynamoCredentials);
                    await apigClient.awsopsGenerateLogPut('', {
                        customerid: secureStorage_getItem('customername'),
                        info: 'Successfully created new SP-API credentials in DynamoDB'
                    }, {
                        headers: { "Authorization": accesstoken }
                    });
                } else {
                    // Update existing credentials
                    await spcredsGQ.updateAmazonSPCreds(dynamoCredentials);
                    await apigClient.awsopsGenerateLogPut('', {
                        customerid: secureStorage_getItem('customername'),
                        info: 'Successfully updated SP-API credentials in DynamoDB'
                    }, {
                        headers: { "Authorization": accesstoken }
                    });
                }
            } catch (dynamoError) {
                // Log DynamoDB error but don't fail the entire process
                await apigClient.awsopsGenerateLogPut('', {
                    customerid: secureStorage_getItem('customername'),
                    info: `DynamoDB operation failed: ${dynamoError.message}`,
                    error: JSON.stringify(dynamoError)
                }, {
                    headers: { "Authorization": accesstoken }
                });
                console.error('DynamoDB operation failed:', dynamoError);
                // Don't throw here to allow the process to continue
            }

            // Log successful completion
            await this.logSuccess('Credentials stored successfully in all systems');

        } catch (error) {
            this.handleError('Failed to store credentials', error);
            throw error;
        }
    }

    logSuccess = async (message) => {
        const apigClient = apigClientFactory.newClient();
        await apigClient.awsopsGenerateLogPut('', {
            customerid: secureStorage_getItem("customername"),
            info: message
        }, {
            headers: { "Authorization": localStorage.getItem("accesstoken") }
        });
    }

    handleError = async (message, error) => {
        console.error(message, error);
        this.setState({
            error: message,
            loading: false,
            processingStep: 'error'
        });

        const apigClient = apigClientFactory.newClient();
        await apigClient.awsopsGenerateLogPut('', {
            customerid: secureStorage_getItem("customername"),
            info: `Error: ${message} - ${error.message}`
        }, {
            headers: { "Authorization": localStorage.getItem("accesstoken") }
        });
    }

    renderStatus() {
        const { loading, error, processingStep } = this.state;

        if (error) {
            return (
                <Alert severity="error" style={{ margin: '20px' }}>
                    {error}
                </Alert>
            );
        }

        if (loading) {
            return (
                <div style={{ textAlign: 'center', margin: '20px' }}>
                    <CircularProgress />
                    <Typography variant="body2" style={{ marginTop: '10px' }}>
                        {this.getProcessingStepMessage()}
                    </Typography>
                </div>
            );
        }

        return null;
    }

    getProcessingStepMessage() {
        const messages = {
            initializing: 'Initializing authorization...',
            processing_auth_code: 'Processing authorization code...',
            exchanging_tokens: 'Exchanging tokens...',
            storing_credentials: 'Storing credentials...',
            completed: 'Authorization completed!',
            error: 'Authorization failed'
        };

        return messages[this.state.processingStep] || 'Processing...';
    }

    render() {
        return (
            <div style={{ fontFamily: "Lato" }}>
                <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: "100%", height: "100%" }}>
                    <div style={{
                        backgroundColor: "white",
                        borderRadius: "25px",
                        border: "6px solid",
                        borderColor: "#d7d9de",
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        padding: "30px"
                    }}>
                        {this.renderStatus()}

                        <Typography variant="h4" style={{
                            color: "black",
                            marginTop: '10%',
                            fontFamily: "Lato",
                            fontSize: "35px",
                            fontWeight: "bold"
                        }}>
                            {this.state.error ? 'Authorization Failed' : 'THANK YOU'}
                        </Typography>

                        <Typography style={{
                            color: "black",
                            margin: '30px',
                            fontSize: "18px"
                        }}>
                            {this.state.error
                                ? 'There was an error processing your authorization. Please try again.'
                                : 'eHouse Studio has received your information.'}
                        </Typography>

                        <Link to="/" style={{
                            position: 'relative',
                            fontWeight: "bold",
                            borderRadius: "8px",
                            backgroundColor: "#4E2DD2",
                            color: "white",
                            padding: "0.7em 1.3em",
                            textDecoration: 'none'
                        }}>
                            {this.state.error ? 'Try Again' : 'Take me back to the Dashboard'}
                        </Link>
                    </div>
                    <img style={{ width: "100%", height: "100%", backgroundColor: "#4E2DD2" }} src={background} alt="Background" />
                </Paper>
            </div>
        );
    }
}


export default styled(Step2)(styles);