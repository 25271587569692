import axios from 'axios';

//graphql endpoint for dev-us
const graphqlEndpoint = "https://ddzh7n3sd5er7l3whlni3qauja.appsync-api.us-east-1.amazonaws.com/graphql"
const appsync_apiKey = "da2-26b73c7km5hmhgo5tnz5wdz5py"

const createAmazonSPCreds = (input) => {
    const query = `
        mutation {
            createAmazonSPCreds(
                input:{
                    CustomerID:${input.CustomerID},
                    SellerID: "${input.SellerID}",
                    MarketPlaceId: "${input.MarketPlaceID}", 
                    AccessToken: "${input.AccessToken}", 
                    RefreshToken: "${input.RefreshToken}", 
                }) {
                CustomerID
            }
        }
    `
    const options = {
        method: 'POST',
        url: graphqlEndpoint,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': appsync_apiKey
        },
        data: JSON.stringify({ query: query })
    }

    return axios(options).then(response => {
        if (response.data.errors) {
            throw new Error(response.data.errors[0].message);
        }
        return response.data;
    }).catch(error => {
        console.error('Error in createAmazonSPCreds:', error);
        throw error;
    });
}

const updateAmazonSPCreds = (input) => {
    const query = `
        mutation {
            updateAmazonSPCreds(input:{CustomerID:${input.CustomerID}, SellerID: "${input.SellerID}", MarketPlaceId: "${input.MarketPlaceID}", AccessToken: "${input.AccessToken}", RefreshToken: "${input.RefreshToken}"}) {
                CustomerID
            }
        }
    `

    const options = {
        method: 'POST',
        url: graphqlEndpoint,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': appsync_apiKey
        },
        data: JSON.stringify({ query: query })
    }

    return axios(options).then(response => {
        if (response.data.errors) {
            throw new Error(response.data.errors[0].message);
        }
        return response.data;
    }).catch(error => {
        console.error('Error in updateAmazonSPCreds:', error);
        throw error;
    });
}

const listAmazonSPCreds = (filter, fields, nextToken, limit = 1000) => {
    console.log('Filter:', filter);

    const query = `
        query{
            listAmazonSPCreds(
                filter:${filter}
            ){
                items{
                    ${fields}
                }
              nextToken
            }
        }
    `

    const options = {
        method: 'POST',
        url: graphqlEndpoint,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': appsync_apiKey
        },
        data: JSON.stringify({ query: query })
    }

    return axios(options).then(response => {
        if (response.data.errors) {
            throw new Error(response.data.errors[0].message);
        }
        return response.data.data.listAmazonSPCreds.items;
    }).catch(error => {
        console.error('Error in listAmazonSPCreds:', error);
        throw error;
    });
}

export default {
    createAmazonSPCreds,
    updateAmazonSPCreds,
    listAmazonSPCreds
}